import React from 'react';
import Header from './Header';
import Footer from './Footer';
import VacancyCard from './VacancyCard';

const App = () => {
    return (
        <div className="app-container">
            <Header/>
            <main>
                <div className="vacancy-list">
                    <VacancyCard/>
                    <VacancyCard/>
                    <VacancyCard/>
                    <VacancyCard/>
                    <VacancyCard/>
                    <VacancyCard/>
                </div>
                {/* Добавь еще контент, если нужно */}
            </main>
            <Footer/>
        </div>
    );
};

export default App;
