import React from 'react';
import './VacancyCard.css'; // Подключаем файл стилей
import VacancyImg from './images/testVacancy.png';

const VacancyCard = () => {
    return (
            <div className="vacancy-card">
                <div className="vacancy-header">
                    <img
                        src={VacancyImg}
                        alt="Company Logo"
                        className="vacancy-image"
                    />
                    <div className="vacancy-info">
                        <h2 className="vacancy-title">Шеф-повар</h2>
                        <p className="vacancy-company">
                            Tanuki by GrowFood | 4.7⭐
                        </p>
                        <p className="vacancy-location">Москва, Авиамоторная</p>
                    </div>
                    <div className="vacancy-category urgent">Срочно</div>
                </div>
                <div className="vacancy-details">
                    <p>
                        <strong>Ставка:</strong> 1 500 руб/смена
                    </p>
                    <p>
                        <strong>Удаленность:</strong> 1,3 км от дома
                    </p>
                </div>
                <div className="vacancy-actions">
                    <button className="apply-button">Отправить заявку</button>
                    <div className="action-icons">
                        <i className="icon-heart"></i>
                        <i className="icon-share"></i>
                    </div>
                </div>
            {/* Можно добавить больше карточек вакансий */}
        </div>
    );
};

export default VacancyCard;
