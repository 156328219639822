import React from 'react';
import './Footer.css'; // Подключаем файл стилей

const Footer = () => {
    return (
        <footer className="app-footer">
            <button className="footer-button active">
                <i className="icon-vacancies"></i>
                <span>Вакансии</span>
            </button>
            <button className="footer-button">
                <i className="icon-favorites"></i>
                <span>Избранное</span>
            </button>
            <button className="footer-button">
                <i className="icon-messages"></i>
                <span>Сообщения</span>
            </button>
            <button className="footer-button">
                <i className="icon-profile"></i>
                <span>Профиль</span>
            </button>
        </footer>
    );
};

export default Footer;
