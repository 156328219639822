import React from 'react';
import './Header.css'; // Подключаем файл стилей

const Header = () => {
    return (
        <header className="app-header">
            <div className="header-left">
                <button className="map-button">Карта</button>
            </div>
            <h1 className="header-title">Вакансии</h1>
            <div className="header-right">
                <button className="filter-button">Фильтр</button>
            </div>
        </header>
    );
};

export default Header;
